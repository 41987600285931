import React from "react"
import SystemLogo from "../../images/system.svg"
import { FormattedMessage } from "react-intl"

const SoftDevThumbnail = ({ children, ...props }) => (
  <div {...props}>
    <div className="flex flex-col text-gray-700 items-center text-center px-4 py-2 m-2">
      <img src={SystemLogo} alt="" className="mx-auto h-16 mb-6" />
      <p className="uppercase font-light text-sm">
        <FormattedMessage id="generalServices.businessCritical" />
      </p>
      <h4 className="mb-6 uppercase font-semibold text-xl leading-snug">
        <FormattedMessage id="generalServices.softwareDevelopment" />
      </h4>
      {children}
    </div>
  </div>
)

export default SoftDevThumbnail
