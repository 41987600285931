import React from "react"
import DesignLogo from "../../images/design.svg"
import { FormattedMessage } from "react-intl"

const UiUxThumbnail = ({ children, ...props }) => (
  <div {...props}>
    <div className="flex flex-col text-gray-700 items-center text-center px-4 py-2 m-2">
      <img src={DesignLogo} alt="" className="mx-auto h-16 w-24 mb-6" />
      <p className="uppercase font-light text-sm">
        <FormattedMessage id="generalServices.uiAndUx" />
      </p>
      <h4 className="mb-6 uppercase font-semibold text-xl leading-snug">
        <FormattedMessage id="generalServices.design" />
      </h4>
      {children}
    </div>
  </div>
)

export default UiUxThumbnail
