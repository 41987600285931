import React from "react"

const TechTag = ({ className, children }) => {
  if (!className) {
    className = "bg-gray-200"
  }
  return (
    <a
      href="https://stackshare.io/mak-it/mak-it"
      className={`font-light leading-none text-xs text-gray-700 hover:text-white px-2 py-2 mr-2 my-1 rounded hover:bg-green-400 lg:text-sm lg:px-4 ${className}`}
    >
      {children}
    </a>
  )
}

export default TechTag
