import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"
import { FormattedMessage } from "react-intl"

import DesignCirclePartImage from "../images/mask@3x.png"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  helmet,
  challengeTitle,
  challengeImage,
  challengeDescription,
  solutionTitle,
  solutionDescription,
  solutionDescription2,
  solutionDescription3,
  solutionImage,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative lg:flex container mx-auto py-24 pb-2 px-10 lg:px-32">
        <div className="lg:w-2/3">
          <h1 className="font-semibold text-5xl sm:text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8 md:pb-24">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React.js</TechTag>
          </p>
        </div>
        <div className="lg:w-1/3 mt-10 lg:mt-0 sm:w-3/4">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="mx-auto w-7/8 left-8"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div
        className="relative overflow-hidden"
        style={{ backgroundColor: "#F9F9F9" }}
      >
        <div className="relative container mx-auto py-12 px-10 lg:py-20 lg:pr-32 lg:pl-20 xl:py-28">
          <div className="sm:mb-12 md:mb-0 md:grid md:grid-cols-10">
            <div className="flex col-start-6 col-span-5 order-2 md:items-center">
              <span className="flex flex-shrink-0 w-4 h-4 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-6 lg:h-6"></span>
              <h2 className="relative font-semibold text-xl lg:text-3xl">
                {challengeTitle}
              </h2>
            </div>
            <div className="relative row-span-2 col-span-5 order-1 mt-8 mb-2 py-4 md:my-auto xl:col-span-4">
              <img src={challengeImage.childImageSharp.fixed.src} alt="" />
            </div>
            <img
              src={DesignCirclePartImage}
              alt=""
              className="absolute w-24 transform rotate-180 hidden md:block"
              style={{ left: "5rem", top: "5rem" }}
            />
            <div className="col-start-6 col-span-5 order-3">
              <div className="md:pt-8 md:pl-4 lg:pl-16">
                <p className="relative text-sm text-gray-500 leading-6 lg:text-lg lg:leading-7">
                  {challengeDescription}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION BLOCK*/}
      <div className="container mx-auto py-12 pt-16 pb-0 px-16 md:px-10 lg:px-32">
        <div className="md:flex flex-row">
          <div className="flex flex-1">
            <div className="md:w-1/2 md:py-12">
              <div className="flex col-start-6 col-span-5 order-2 md:items-center">
                <span className="flex flex-shrink-0 w-4 h-4 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-6 lg:h-6"></span>
                <h2 className="relative font-semibold text-xl lg:text-3xl">
                  {solutionTitle}
                </h2>
              </div>
              <p className="mb-10 text-gray-500 mt-10">{solutionDescription}</p>
              <p className="mb-10 text-gray-500">{solutionDescription2}</p>
              <p className="mb-10 text-gray-500">{solutionDescription3}</p>
            </div>

            <div className="md:w-2/3 hidden md:block flex items-center">
              <img
                src={solutionImage.childImageSharp.fixed.src}
                alt=""
                className=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 lg:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="md:flex md:flex-row justify-center">
          <SoftDevThumbnail className="md:w-1/2" />
          <UiUxThumbnail className="md:w-1/2" />
        </div>
      </div>
    </div>
  )
}

const GAIS = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default GAIS

export const pageQuery = graphql`
  query GAISID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        mainDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitle
        challengeDescription
        challengeImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionTitle
        solutionDescription
        solutionDescription2
        solutionDescription3
        solutionImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
